import React, { useState } from "react";
import upload from "../assets/upload.png";
import { toast } from "react-toastify";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./helpers/Cropper";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
const ImageUpload = ({
  showModal,
  closeModal,
  id,
  bannerImage,
  handleBannerImageChange,
}) => {
  const [updateImage, setUpdateImage] = useState(false);
  const [image, setImage] = useState(null);
  const [url, setURL] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const [selectedLink, setSelectedLink] = useState("");
  const handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer?.files[0];
    console.log(file);
    if (file) {
      setImage(file);
      const imageURL = URL.createObjectURL(file);
      setImageURL(imageURL);
    }
    const formdata = new FormData("file", file);
    handleUpload(formdata);
  };

  const handleImageURLUpload = (e) => {
    e.preventDefault();
    if (url === "") {
      toast.error("Inavlid Url");
      return;
    }
    setImageURL(url);
    setImageUploaded(true);
  };

  const handleUpload = async (file) => {
    try {
      console.log(file);
      setIsUploading(true);
      if (!image && !file) {
        alert("Please select a file to upload!");
        return;
      }

      const imgData = new FormData();
      imgData.append("file", image);
      console.log(imgData);
      for (const [key, value] of imgData.entries()) {
        console.log(key, value);
      }
      const response = await axios.post(
        `${BASE_URL}/upload`,
        file ? file : imgData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentage);
          },
        }
      );
      console.log(response);
      if (response?.data?.data?.filename) {
        setSelectedLink(response?.data?.data?.filename);
        setIsUploading(false);
        setImageUploaded(true);
        setUploadProgress(0);
        return response.data.data.filename;
      } else {
        setIsUploading(false);
        setImageUploaded(false);
        setUploadProgress(0);
        return null;
      }
    } catch (error) {
      setIsUploading(false);
      setImageUploaded(false);
      setUploadProgress(0);
      console.log(error);
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      }
      return null;
    }
    // const fakeUpload = new Promise((resolve, reject) => {
    //   setIsUploading(true);

    //   let progress = 0;
    //   const interval = setInterval(() => {
    //     progress += 10;
    //     setUploadProgress(progress);
    //     if (progress > 100) {
    //       clearInterval(interval);
    //       resolve();
    //     }
    //   }, 300);
    // });

    // fakeUpload.then(() => {
    //   toast.success("Upload completed");
    //   setIsUploading(false);
    //   setImageUploaded(true);
    //   setUploadProgress(0);
    // });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setImage(file);
      const imageURL = URL.createObjectURL(file);
      setImageURL(imageURL);
      setSelectedLink(imageURL);
      setIsUploading(false);
      setImageUploaded(true);
    }
  };

  const handleUpdateImage = async () => {
    try {
      const CropperImage = await getCroppedImg(
        selectedLink,
        croppedAreaPixels,
        { width: 1080, height: 350 }
      );
      const croppedBlob = CropperImage.blob;

      const file = new File([croppedBlob], `cropped_image_${Date.now()}.jpg`, {
        type: croppedBlob.type,
      });

      const formData = new FormData();
      formData.append("file", file);

      console.log(formData);
      const filename = await handleUpload(formData);
      if (filename) {
        const payload = { path: filename };
        if (filename) {
          const response = await axios.post(
            `${BASE_URL}/update-thumbnail/${id}`,
            payload
          );
          console.log(response.data.success);
          if (response.data.success) {
            handleBannerImageChange(filename);
            toast.success("Thumbnail updated Successfully");
            closeModal();
          }
        } else {
          toast.error("Please select an image");
          closeModal();
        }
        setImage(null);
        setImageURL("");
        setImageUploaded(false);
        setUpdateImage(false);
      }
    } catch (error) {
      console.log(error);
      setImage(null);
      setImageURL("");
      setImageUploaded(false);
      setUpdateImage(false);

      if (error?.response?.data?.error) {
        toast.error(error?.response?.data.data.error);
      }
    }
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const resetImageState = () => {
    setImage(null);
    setImageURL("");
    setImageUploaded(false);
    setURL("");
    setSelectedLink("");
    setUpdateImage(false);
  };

  return (
    <div className="row d-flex justify-content-center align-items-center mt-5">
      {!updateImage ? (
        <div className="col-8" style={{ position: "relative" }}>
          <div className=" d-flex justify-content-between">
            <h3>Course Banner Image</h3>
            <button
              className="btn btn-primary"
              onClick={() => {
                setUpdateImage(true);
              }}
              style={{ backgroundColor: "#3a3a8f" }}
            >
              Update Thumbnail
            </button>
          </div>
          <img
            src={bannerImage}
            alt="img"
            style={{ width: "100%", height: "400px" }}
          ></img>
          {/* <div
            className="mt-2"
            style={{
              backgroundImage: `url(${bannerImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              width: "100%",
              height: "400px",
            }}
          ></div> */}
        </div>
      ) : (
        <div className="col-8">
          <div
            className="d-flex justify-content-start align-items-center"
            style={{ height: "50px" }}
          >
            <h3>Upload Course Banner</h3>
          </div>

          <div>
            <div
              className={
                !imageUploaded
                  ? `rounded shadow-none d-flex justify-content-center align-items-center flex-column p-3 imageHover`
                  : `cropper-container`
              }
              onDragEnter={(e) => handleEnter(e)}
              onDragLeave={(e) => handleLeave(e)}
              onDragOver={(e) => handleOver(e)}
              onDrop={(e) => handleDrop(e)}
              style={{
                border: `${!imageUploaded ? "1px dashed black" : "none"}`,
                textAlign: "center",
                // backgroundImage: `url(${imageUploaded ? imageURL : ""})`,
                // backgroundPosition: "center",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "contain",
                width: "100%",
                height: "350px",
              }}
            >
              {!imageUploaded ? (
                <div>
                  <img
                    src={upload}
                    alt="file upload"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <p>Drag and Drop image here</p>
                  <p>-OR-</p>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center w-100"
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <input
                      type="file"
                      name="imageInput"
                      id="inputFile"
                      accept="image/*"
                      style={{ display: "none" }}
                      required
                      onChange={handleImageUpload}
                    />
                    <label
                      htmlFor="inputFile"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#3a3a8f" }}
                    >
                      Browse
                    </label>
                  </div>
                </div>
              ) : (
                <div className="cropper-background">
                  <Cropper
                    image={selectedLink}
                    crop={crop}
                    zoom={zoom}
                    aspect={16 / 9}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    minZoom={1}
                    maxZoom={3}
                    objectFit="horizontal-cover"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              )}
            </div>
            {!imageUploaded && !image && (
              <>
                <p className="text-center my-2">-OR-</p>

                <div className="d-flex justify-content-between  w-100">
                  <input
                    type="url"
                    placeholder="Enter Image URL"
                    className="p-2 w-100 mr-2"
                    onChange={(e) => {
                      // setImage(e.target.value);
                      setURL(e.target.value);
                      setSelectedLink(e.target.value);
                    }}
                  />
                  <input
                    type="submit"
                    className="btn btn-primary"
                    style={{ backgroundColor: "#3a3a8f" }}
                    onClick={handleImageURLUpload}
                  />
                </div>
              </>
            )}

            {imageUploaded ? (
              <div className="d-flex justify-content-center my-3">
                <input
                  type="reset"
                  className="btn btn-danger mx-1"
                  value="Cancel"
                  style={{ display: "inline-block" }}
                  onClick={() => {
                    setImage(null);
                    setImageURL("");
                    setImageUploaded(false);
                    setURL("");
                    setSelectedLink("");
                    setUpdateImage(false);
                  }}
                />
                <input
                  type="submit"
                  className="btn btn-warning mx-1"
                  value="Submit"
                  style={{
                    display: "inline-block",
                    backgroundColor: "#3a3a8f",
                    color: "white",
                  }}
                  onClick={() => {
                    handleUpdateImage();
                    // toast.success("Image uploaded successfully!");
                    // setImage(null);
                    // setImageURL("");
                    // setImageUploaded(false);
                  }}
                />
              </div>
            ) : (
              image && (
                <>
                  <div className="container mt-3">
                    {/* File Name Display */}
                    {image.name && (
                      <div
                        className="alert alert-warning text-center"
                        role="alert"
                      >
                        {image.name}
                      </div>
                    )}

                    {/* File Preview Image */}
                    {image && (
                      <div className="text-center mb-3">
                        <img
                          src={imageURL}
                          alt="Uploaded preview"
                          className="img-thumbnail"
                          style={{ maxWidth: "300px", height: "auto" }}
                        />
                      </div>
                    )}

                    {/* Upload Button */}
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleUpload();
                        }}
                        disabled={isUploading}
                        style={{
                          cursor: isUploading ? "not-allowed" : "pointer",
                          backgroundColor: "#3a3a8f",
                          borderColor: "#3a3a8f",
                        }}
                      >
                        {isUploading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Uploading...
                          </>
                        ) : (
                          "Upload"
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )
            )}
            {isUploading && (
              <div style={{ marginTop: "20px" }}>
                <div
                  style={{
                    height: "10px",
                    width: "100%",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: `${uploadProgress}%`,
                      backgroundColor: "#007BFF",
                      borderRadius: "5px",
                      transition: "width 0.3s",
                    }}
                  ></div>
                </div>
                <p style={{ textAlign: "center", marginTop: "5px" }}>
                  {uploadProgress}%
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
