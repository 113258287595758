import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Styled components
const LayoutContainer = styled.div`
  display: flex;
  max-width: 100%;
  //   margin: 1% 0% 0% 3%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 85vh;
  overflow-y: auto;
`;

const Sidebar = styled.div`
  width: 35%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 90%;
  margin: auto;
  padding: 20px;
`;

const NavigationButton = styled.button`
  background-color: transparent;
  border-radius: 25%;
  border: none;
  font-size: 2rem;
  color: #3a3a8f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    color: #959dad;
    // background-color: #8d8b89;
    cursor: not-allowed;
  }
`;
const CourseTitle = styled.h2`
  background-color: #2a2a72;
  color: white;
  margin: 3% auto;
  font-size: 1.8em; /* Increase font size for emphasis */
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 3px solid #3a3a8f;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const ModuleTitle = styled.div`
  font-weight: bold;
  font-size: 1em;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3a3a8f;
  background-color: ${({ isActive }) => (isActive ? "#2a2a72" : "#d3d3d3")};
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#3a3a8f")};
  border: ${({ isActive }) => (isActive ? "2px solid #3a3a8f" : "none")};
  transition: background-color 0.3s, color 0.3s;
`;

const LessonTitle = styled.div`
  font-weight: bold;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #3a3a8f;
  background-color: #dee1e4;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
`;

const LessonContent = styled.div`
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-top: 10px;
  overflow-y: auto; /* Make lessons scrollable */
  max-height: 100%; /* Limit height to parent container */
  padding-right: 15px; /* Add padding for scrollbar spacing */
`;

const ContentHeader = styled.h5`
  font-size: 1em;
  color: #3a3a8f;
  margin-top: 15px;
  font-weight: bold;
`;

const ContentDescription = styled.p`
  font-size: 0.9em;
  color: #555;
  margin-top: 5px;
`;

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const LessonsList = styled.div`
  margin-top: 0.5rem;
  padding-left: 1.5rem;
  //   border-left: 2px solid #3a3a8f;
`;

// Style for each lesson item
const LessonItem = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #3a3a8f;
  transition: color 0.2s ease-in-out;

  background-color: ${({ isActive }) => (isActive ? "#f0f8ff" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  border-left: ${({ isActive }) => (isActive ? "2px solid #3a3a8f" : "none")};
  &:hover {
    background-color: #e6f2ff;
  }
  &:hover {
    color: #2a2a6a;
    font-weight: bold;
  }
`;

const NavigationButtonLeft = styled(NavigationButton)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  border: "none";
  //   background-color: #2a2a72;
  color: #2a2a72;
`;

const NavigationButtonRight = styled(NavigationButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  border: "none";
  //   background-color: #2a2a72;
  color: #2a2a72;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem; /* Adjust icon size */
  transition: transform 0.3s ease-in-out;

  ${NavigationButtonLeft}:hover & {
    transform: scale(1.2); /* Slightly enlarge the icon on hover */
  }
`;

const CourseOutline = ({ courseOutline, courseStage }) => {
  console.log("courseoutline-------------", courseOutline);
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(null);
  const [expandedLessons, setExpandedLessons] = useState({});
  // const [courseOutline, setCourseOutline] = useState(courseOutlineData);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const divRef = useRef(null);

  const handleModuleClick = (index) => {
    setSelectedModuleIndex(index);
    setCurrentLessonIndex(0);
  };

  const handlePreviousLesson = () => {
    const currentModuleLessons =
      courseOutline.modules[selectedModuleIndex]?.lessons || [];

    if (currentLessonIndex === 0 && selectedModuleIndex > 0) {
      setSelectedModuleIndex((prevIndex) => prevIndex - 1);
      setCurrentLessonIndex(
        courseOutline.modules[selectedModuleIndex - 1].lessons.length - 1
      );
    } else if (currentLessonIndex > 0) {
      setCurrentLessonIndex((prevIndex) => prevIndex - 1);
    }
    scrollToTop();
  };

  const handleNextLesson = () => {
    const currentModuleLessons =
      courseOutline.modules[selectedModuleIndex]?.lessons || [];

    if (
      currentLessonIndex === currentModuleLessons.length - 1 &&
      selectedModuleIndex < courseOutline.modules.length - 1
    ) {
      setSelectedModuleIndex((prevIndex) => prevIndex + 1);
      setCurrentLessonIndex(0);
    } else if (currentLessonIndex < currentModuleLessons.length - 1) {
      setCurrentLessonIndex((prevIndex) => prevIndex + 1);
    }
    scrollToTop();
  };
  const handleLessonClick = (moduleIndex, lessonIndex) => {
    setSelectedModuleIndex(moduleIndex);
    setCurrentLessonIndex(lessonIndex);
  };

  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  };

  // return (
  //   <>
  //     {courseOutline && (
  //       <>
  //         <CourseTitle className="m-0 shadow-lg p-3">
  //           {courseOutline?.title || ""}
  //         </CourseTitle>
  //         {courseOutline?.modules && (
  //           <LayoutContainer className="mt-3 pr-2">
  //             <Sidebar className="rounded shadow-sm z-3">
  //               <div className="d-flex mb-2">
  //                 <h2>Chapters</h2>
  //               </div>

  //               {courseOutline?.modules?.map((module, index) => (
  //                 <div key={index}>
  //                   <ModuleTitle
  //                     isActive={selectedModuleIndex === index}
  //                     onClick={() => handleModuleClick(index)}
  //                     className="mb-3"
  //                   >
  //                     <div>{`Chapter ${module.moduleNumber}: ${module.moduleTitle}`}</div>
  //                   </ModuleTitle>

  //                   {selectedModuleIndex === index && (
  //                     <LessonsList>
  //                       {courseOutline.modules[selectedModuleIndex].lessons.map(
  //                         (lesson, lessonIndex) => (
  //                           <LessonItem
  //                             className="mb-1"
  //                             key={lessonIndex}
  //                             onClick={() =>
  //                               handleLessonClick(
  //                                 selectedModuleIndex,
  //                                 lessonIndex
  //                               )
  //                             }
  //                             isActive={currentLessonIndex === lessonIndex}
  //                           >
  //                             {`Lesson ${lesson.lessonNumber}: ${lesson.lessonTitle}`}
  //                           </LessonItem>
  //                         )
  //                       )}
  //                     </LessonsList>
  //                   )}
  //                 </div>
  //               ))}
  //             </Sidebar>

  //             <MainContent className="p-2">
  //               {" "}
  //               {selectedModuleIndex !== null && (
  //                 <>
  //                   <NavigationButtonLeft
  //                     title="Previous"
  //                     className="m-0 mr-3  ml-2"
  //                     onClick={handlePreviousLesson}
  //                     disabled={
  //                       selectedModuleIndex === 0 && currentLessonIndex === 0
  //                     }
  //                   >
  //                     <StyledIcon icon={faChevronLeft} />
  //                   </NavigationButtonLeft>

  //                   <div
  //                     style={{ overflow: "auto", scrollbarWidth: "none" }}
  //                     className="px-2 m-3"
  //                     ref={divRef}
  //                   >
  //                     <h3>
  //                       {courseOutline.modules[selectedModuleIndex].moduleTitle}
  //                     </h3>
  //                     {courseOutline.modules[selectedModuleIndex].lessons
  //                       .length > 0 && (
  //                       <div className="lesson-carousel">
  //                         <div className="lesson-content">
  //                           <LessonTitle>
  //                             {`Lesson ${courseOutline.modules[selectedModuleIndex].lessons[currentLessonIndex].lessonNumber}: ${courseOutline.modules[selectedModuleIndex].lessons[currentLessonIndex].lessonTitle}`}
  //                           </LessonTitle>
  //                           <LessonContent>
  //                             <p>
  //                               <strong>
  //                                 {
  //                                   courseOutline.modules[selectedModuleIndex]
  //                                     .lessons[currentLessonIndex].description
  //                                 }
  //                               </strong>{" "}
  //                             </p>
  //                             {courseOutline.modules[selectedModuleIndex]
  //                               .lessons[currentLessonIndex].detailcontent ? (
  //                               <>
  //                                 <div
  //                                   dangerouslySetInnerHTML={{
  //                                     __html:
  //                                       courseOutline.modules[
  //                                         selectedModuleIndex
  //                                       ].lessons[currentLessonIndex]
  //                                         .detailcontent,
  //                                   }}
  //                                 />
  //                               </>
  //                             ) : (
  //                               <Skeleton count={10} />
  //                             )}
  //                             {courseOutline.modules[selectedModuleIndex]
  //                               .lessons[currentLessonIndex].content &&
  //                               courseOutline.modules[
  //                                 selectedModuleIndex
  //                               ].lessons[currentLessonIndex].content.map(
  //                                 (contentItem, contentIndex) => (
  //                                   <div key={contentIndex}>
  //                                     <ContentHeader>
  //                                       {`${selectedModuleIndex + 1}.${
  //                                         currentLessonIndex + 1
  //                                       }.${contentIndex + 1} ${
  //                                         contentItem.titleName
  //                                       }`}
  //                                     </ContentHeader>
  //                                     <ContentDescription>
  //                                       {contentItem.titleDescription}
  //                                     </ContentDescription>

  //                                     <p>
  //                                       <strong>YouTube Search Terms:</strong>
  //                                     </p>

  //                                     <ul>
  //                                       {contentItem.youtubeSearchTerms ? (
  //                                         contentItem?.youtubeSearchTerms.map(
  //                                           (term, termIndex) => (
  //                                             <li key={termIndex}>{term}</li>
  //                                           )
  //                                         )
  //                                       ) : (
  //                                         <Skeleton count={4} />
  //                                       )}
  //                                     </ul>
  //                                     {contentItem.YoutubeLinks ? (
  //                                       <VideoGrid>
  //                                         {contentItem.YoutubeLinks.map(
  //                                           (linkGroup, i) => (
  //                                             <VideoWrapper key={i}>
  //                                               <iframe
  //                                                 src={
  //                                                   linkGroup &&
  //                                                   linkGroup[0]?.URL
  //                                                     ? linkGroup[0].URL
  //                                                     : linkGroup?.URL
  //                                                 }
  //                                                 title={
  //                                                   linkGroup &&
  //                                                   linkGroup[0]?.title
  //                                                     ? linkGroup[0].title
  //                                                     : linkGroup?.title
  //                                                 }
  //                                                 className="rounded shadow-sm"
  //                                                 style={{ border: "none" }}
  //                                                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //                                               ></iframe>
  //                                             </VideoWrapper>
  //                                           )
  //                                         )}
  //                                       </VideoGrid>
  //                                     ) : (
  //                                       <Skeleton count={5} />
  //                                     )}
  //                                   </div>
  //                                 )
  //                               )}
  //                           </LessonContent>
  //                         </div>
  //                       </div>
  //                     )}
  //                   </div>
  //                   <NavigationButtonRight
  //                     title="next"
  //                     className="m-0 mr-3  ml-2"
  //                     onClick={handleNextLesson}
  //                     disabled={
  //                       selectedModuleIndex ===
  //                         courseOutline.modules.length - 1 &&
  //                       currentLessonIndex ===
  //                         courseOutline.modules[selectedModuleIndex].lessons
  //                           .length -
  //                           1
  //                     }
  //                   >
  //                     <StyledIcon icon={faChevronRight} />
  //                   </NavigationButtonRight>
  //                 </>
  //               )}
  //             </MainContent>
  //           </LayoutContainer>
  //         )}
  //       </>
  //     )}
  //   </>
  // );
  return (
    <>
      {courseOutline && (
        <>
          <CourseTitle className="m-0 shadow-lg p-3">
            {courseOutline?.title || ""}
          </CourseTitle>
          {courseOutline?.modules && (
            <LayoutContainer className="mt-3 pr-2">
              <Sidebar className="rounded shadow-sm z-3">
                <div className="d-flex mb-2">
                  <h2>Chapters</h2>
                </div>

                {courseOutline?.modules?.map((module, index) => (
                  <div key={index}>
                    <ModuleTitle
                      isActive={selectedModuleIndex === index}
                      onClick={() => handleModuleClick(index)}
                      className="mb-3"
                    >
                      <div>{`Chapter ${module.moduleNumber}: ${module.moduleTitle}`}</div>
                    </ModuleTitle>

                    {selectedModuleIndex === index && (
                      <LessonsList>
                        {courseOutline.modules[selectedModuleIndex].lessons.map(
                          (lesson, lessonIndex) => (
                            <LessonItem
                              className="mb-1"
                              key={lessonIndex}
                              onClick={() =>
                                handleLessonClick(
                                  selectedModuleIndex,
                                  lessonIndex
                                )
                              }
                              isActive={currentLessonIndex === lessonIndex}
                            >
                              {`Lesson ${lesson.lessonNumber}: ${lesson.lessonTitle}`}
                            </LessonItem>
                          )
                        )}
                      </LessonsList>
                    )}
                  </div>
                ))}
              </Sidebar>

              <MainContent className="p-2">
                {" "}
                {selectedModuleIndex !== null && (
                  <>
                    <NavigationButtonLeft
                      title="Previous"
                      className="m-0 mr-3  ml-2"
                      onClick={handlePreviousLesson}
                      disabled={
                        selectedModuleIndex === 0 && currentLessonIndex === 0
                      }
                    >
                      <StyledIcon icon={faChevronLeft} />
                    </NavigationButtonLeft>

                    <div
                      style={{ overflow: "auto", scrollbarWidth: "none" }}
                      className="px-2 m-3"
                      ref={divRef}
                    >
                      <h3>
                        {courseOutline.modules[selectedModuleIndex].moduleTitle}
                      </h3>
                      {courseOutline.modules[selectedModuleIndex].lessons
                        .length > 0 && (
                        <div className="lesson-carousel">
                          <div className="lesson-content">
                            <LessonTitle>
                              {`Lesson ${courseOutline.modules[selectedModuleIndex].lessons[currentLessonIndex].lessonNumber}: ${courseOutline.modules[selectedModuleIndex].lessons[currentLessonIndex].lessonTitle}`}
                            </LessonTitle>
                            <LessonContent>
                              <p>
                                <strong>
                                  {
                                    courseOutline.modules[selectedModuleIndex]
                                      .lessons[currentLessonIndex].description
                                  }
                                </strong>{" "}
                              </p>
                              {courseOutline.modules[selectedModuleIndex]
                                .lessons[currentLessonIndex].combinedContent ? (
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        courseOutline.modules[
                                          selectedModuleIndex
                                        ].lessons[currentLessonIndex]
                                          .combinedContent,
                                    }}
                                  />
                                </>
                              ) : (
                                <Skeleton count={10} />
                              )}
                              {courseOutline.modules[selectedModuleIndex]
                                .lessons[currentLessonIndex].content &&
                                courseOutline.modules[
                                  selectedModuleIndex
                                ].lessons[currentLessonIndex].content.map(
                                  (contentItem, contentIndex) => (
                                    <div key={contentIndex}>
                                      <ContentHeader>
                                        {`${selectedModuleIndex + 1}.${
                                          currentLessonIndex + 1
                                        }.${contentIndex + 1} ${
                                          contentItem.titleName
                                        }`}
                                      </ContentHeader>
                                      <ContentDescription>
                                        {contentItem.titleDescription}
                                      </ContentDescription>

                                      {/* <p>
                                        <strong>YouTube Search Terms:</strong>
                                      </p> */}

                                      {/* <ul> */}
                                        {/* {contentItem.youtubeSearchTerms ? (
                                          contentItem?.youtubeSearchTerms.map(
                                            (term, termIndex) => (
                                              <li key={termIndex}>{term}</li>
                                            )
                                          )
                                        ) : (
                                          <Skeleton count={4} />
                                        )} */}
                                      {/* </ul> */}
                                      {/* {contentItem.YoutubeLinks ? (
                                        <VideoGrid>
                                          {contentItem.YoutubeLinks.map(
                                            (linkGroup, i) => (
                                              <VideoWrapper key={i}>
                                                <iframe
                                                  src={
                                                    linkGroup &&
                                                    linkGroup[0]?.URL
                                                      ? linkGroup[0].URL
                                                      : linkGroup?.URL
                                                  }
                                                  title={
                                                    linkGroup &&
                                                    linkGroup[0]?.title
                                                      ? linkGroup[0].title
                                                      : linkGroup?.title
                                                  }
                                                  className="rounded shadow-sm"
                                                  style={{ border: "none" }}
                                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                ></iframe>
                                              </VideoWrapper>
                                            )
                                          )}
                                        </VideoGrid>
                                      ) : (
                                        <Skeleton count={5} />
                                      )} */}
                                    </div>
                                  )
                                )}
                            </LessonContent>
                          </div>
                        </div>
                      )}
                    </div>
                    <NavigationButtonRight
                      title="next"
                      className="m-0 mr-3  ml-2"
                      onClick={handleNextLesson}
                      disabled={
                        selectedModuleIndex ===
                          courseOutline.modules.length - 1 &&
                        currentLessonIndex ===
                          courseOutline.modules[selectedModuleIndex].lessons
                            .length -
                            1
                      }
                    >
                      <StyledIcon icon={faChevronRight} />
                    </NavigationButtonRight>
                  </>
                )}
              </MainContent>
            </LayoutContainer>
          )}
        </>
      )}
    </>
  );
};

export default CourseOutline;
