export async function getCroppedImg(imageSrc, croppedAreaPixels) {
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous'; // Handle cross-origin images
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
            img.src = url;
        });

    const canvasToBlob = (canvas) =>
        new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    console.log("Blob created successfully:", blob);
                    resolve(blob);
                } else {
                    reject("Failed to create Blob");
                }
            }, 'image/png', 1);
        });

    try {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Scale the crop dimensions to the image's natural size
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        // Set canvas dimensions based on the cropped area
        canvas.width = croppedAreaPixels.width * scaleX;
        canvas.height = croppedAreaPixels.height * scaleY;

        // Draw the cropped area onto the canvas
        ctx.drawImage(
            image,
            croppedAreaPixels.x * scaleX, // Source x-coordinate
            croppedAreaPixels.y * scaleY, // Source y-coordinate
            croppedAreaPixels.width * scaleX, // Source width
            croppedAreaPixels.height * scaleY, // Source height
            0, // Destination x-coordinate
            0, // Destination y-coordinate
            canvas.width, // Destination width
            canvas.height // Destination height
        );

        // Convert the canvas to a Blob
        const blob = await canvasToBlob(canvas);
        const croppedImageURL = URL.createObjectURL(blob);

        return { blob, croppedImageURL }; // Return both blob and URL for flexibility
    } catch (error) {
        console.error('Error cropping the image:', error);
        throw error;
    }
}