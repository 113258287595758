import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import CourseList from "./CourseList";

// Styled container for the entire search component
const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // height: 100vh;
`;

// Search input bar
const SearchInput = styled.input`
  width: 70%;
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;

  &:focus {
    border-color: #3a3a8f;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

// Button for submitting the search
const SearchButton = styled.button`
  padding: 12px 20px;
  background-color: #3a3a8f;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 70%;
  font-weight: bold;

  &:hover {
    background-color: #2b2b6d;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

// Results container
const ResultsContainer = styled.div`
  margin-top: 30px;
  width: 80%;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
`;

// Result item styling
const ResultItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #333;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

// Loading spinner
const LoadingSpinner = styled.div`
  margin-top: 20px;
  font-size: 1.5em;
  color: #3a3a8f;
`;

const SearchBarComponent = ({
  handleSearchChange,
  loading,
  query,
  handleSearchSubmit,
  courselist,
}) => {
  const handleEnter = (event) => {
    if (event.key === "Enter" && query) {
      handleSearchSubmit();
    }
  };

  return (
    <>
      <SearchContainer>
        <h2 className="m-3">India AI</h2>
        <SearchInput
          type="text"
          value={query}
          onChange={handleSearchChange}
          onKeyDown={handleEnter}
          placeholder="Enter query to generate course"
        />
        <SearchButton onClick={handleSearchSubmit}>Generate</SearchButton>

        {loading && <LoadingSpinner>Loading...</LoadingSpinner>}
      </SearchContainer>

      <CourseList results={courselist} />
    </>
  );
};

export default SearchBarComponent;
